<template>
  <div class="detail">
    <el-card header="您的信息" v-loading="loading">
      <el-form>
        <el-form-item v-for="(item, idx) in datas" :key="idx" :label="item.title">{{ sourceData[idx] }}
        </el-form-item>
      </el-form>
    </el-card>
    <el-card :body-style="{ padding: 0 }" v-loading="loading">
      <template #header>
        <div class="detail-order">
          <div>订单号：{{ sourceData['order_sn'] }}</div>
          <div class="detail-order-time">{{ sourceData['created_at'] }}</div>
          <div class="btn-group">
            <el-button type="warning" :loading="downQuoteLoading" @click="downQuoteOrder"
              v-if="sourceData.has_quote">下载报价单</el-button>
          </div>
        </div>
      </template>
        <Good v-for="(item, idx) in sourceData.order_goods_items" :key="idx" :item="item"  />
      <!-- <div v-if="sourceData.has_quote" class="sum-container">
        <div>合计：{{ priceFormat(sourceData.order_amount, sourceData.currency) }}</div>
      </div> -->
    </el-card>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { orderInfoView, downloadQuoteOrder } from '@/api/order.js'
import { useRoute } from 'vue-router'
import Good from './components/Good.vue'
const sourceData = ref({})
const route = useRoute()
const loading = ref(false)
const downQuoteLoading = ref(false)

const init = (order_sn) => {
  orderInfoView({ order_sn })
    .then((res) => {
      const { error, data } = res
      if (error == 0) {
        sourceData.value = data
      }
      loading.value = false
    })
    .catch(() => {
      loading.value = false
    })
}
watch(
  route,
  (e) => {
    const { query } = e || {}
    const { order_sn } = query || {}
    if (order_sn) {
      loading.value = true
      init(order_sn)
    }
  },
  {
    immediate: true
  }
)
const lists = ref({
  order_sn: {
    title: '订单号'
  },
  created_at: {
    title: '下单时间'
  } // 手机号
})
const datas = ref({
  consignee: {
    title: '收货人'
  },
  mobile: {
    title: '联系电话'
  }, // 手机号
  company_title: {
    title: '公司名称'
  }, // 公司名称
  email: {
    title: '邮箱',
    type: 'email'
  }, // 邮箱
  note: {
    title: '您的需求',
    type: 'textarea',
    required: false
  } // 邮箱
})
const downQuoteOrder = () => {
  downQuoteLoading.value = true
  const order_sn = sourceData.value.order_sn
  downloadQuoteOrder({ order_sn }).then(res => {
    downQuoteLoading.value = false

    fetch(res.data.download_url)
      .then(res => res.blob())
      .then(blob => {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style.display = 'none'
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = '报价单_' + order_sn
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      })
  }).catch(err => {
    downQuoteLoading.value = false
  })
}
</script>

<style lang="scss" scoped>
.detail {
  margin-left: -15px;

  &-row {
    margin-left: -10px;
    margin-right: -10px;
  }

  &-info {
    margin: 0 10px;
    min-height: 260px;
  }

  :deep(.goods-info) {}

  .goods-info {
    width: 70%;
  }

  .sum-container {
    padding: 10px 15px;
    text-align: right;
  }

  &-order {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-time {
      color: #999;
    }
  }
}

.el-card {
  margin-bottom: 20px;
}

.el-form {
  &-item {
    margin-bottom: 0;

    :deep(.el-form-item__label) {
      font-size: 18px;
      // width: 90px;
      text-align: justify;
      position: relative;
      //   top: 16px;
      margin-bottom: -32px;
      flex-wrap: wrap;

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 0;
        line-height: 0;
      }
    }

    :deep(.el-form-item__content) {
      font-size: 18px;
    }
  }
}

</style>
